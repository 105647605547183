import React, { useCallback, useMemo, useContext, useState, useEffect } from "react";
import { Box, makeStyles, Theme, useMediaQuery, Grid } from "@material-ui/core";
import {
  DashboardTrucksData,
  TruckSummary,
  columnTypeTranslation,
  Can,
  Module,
  ErrorMsg,
} from "interfaces";
import { useNavigate } from "react-router-dom";
import { TruckGroup, TruckCard } from "..";
import {
  AddTrucksCerroAltoDialog,
  CustomSnackbar,
  Header,
  TruckIcon,
  ExcelIcon,
  TruckGroupSkeleton,
  PreAssembleOperatorAssignmentKpis,
  ScreenContainer,
  ContentPasteRounded,
} from "components";
import { TruckColumn } from "../TruckColumn";
import {
  UserContext,
  InfoSourceContext,
  TrucksDashboardContext,
} from "contexts";
import {
  AppRoute,
  filterDashboardTrucks,
  generateFilteredTrucksData,
  generateTrucksExcel,
  generateTrucksExcelPreAssignment,
} from "utils";
import { useData, useSearch } from "hooks";
import Fuse from "fuse.js";
import { CloseSharp } from "@material-ui/icons";
import { ShiftOperativeTrucksDialog } from "../ShiftOperativeTrucksDialog";
import { PlannedMaintenancesDialog } from "../PlannedMaintenancesDialog";
import {
  OperatorAssignmentSpeedDial,
  DialAction,
} from "../OperatorAssigmentSpeedDial";
import SmallBox from "screens/SC/SmallBox";
import { StatusInterface } from "contexts/ShifChangeContext";
import { STANDARD_POLLING } from "App";
import { TrucksDashboardPrearmedContext } from "contexts/TruckDashboardPrearmedContext";
import { PreAssembledTruckCard } from "./PreAssembledTruckCard";

const filterOptions: Fuse.IFuseOptions<TruckSummary> = {
  keys: ["id"],
  threshold: 0.1,
  minMatchCharLength: 3,
};

interface IProps {
  clicked: number;
}

const BYPASS_OUTDATED_DATA =
  process.env.REACT_APP_BYPASS_OUTDATED_DATA === "true";

export const PreAssembleTrucksDashboardScreen: React.FC<IProps> = ({clicked}) => {
  const classes = useStyles();
  const withSpace = useMediaQuery((theme: Theme) => theme.breakpoints.up(1500));
  const navigate = useNavigate();
  const { isAllowedTo } = useContext(UserContext);
  const { magnetElapsedMinutes, firstLoadingSourceInfo, refetchingSourceInfo } =
    useContext(InfoSourceContext);
  const {
    data: trucks,
    firstLoading: loadingTrucks,
    refetching: refetchingTrucks,
    polling: pollingTrucks,
    refetch: refetchTrucksDashboard,
  } = useContext(TrucksDashboardPrearmedContext);

  const [openCerroAltoDialog, setOpenCerroAltoDialog] =
    useState<boolean>(false);
  const [openOperativeTrucksDialog, setOpenOperativeTrucksDialog] =
    useState<boolean>(false);
  const [openPlannedMaintenancesDialog, setOpenPlannedMaintenancesDialog] =
    useState<boolean>(false);

  const [openSpeedDial, setOpenSpeedDial] = useState<boolean>(false);

  const {data, firstLoading} = useData<StatusInterface>(
    {
        config: "/shift-change/visualization/status",
        options: {
            useCache: false,
          },
          ...STANDARD_POLLING,
    },
    ErrorMsg.GET_STATUS_SHIFT_CHANGE
  );
  const { shift, dateFormat, maxPage, pages } = data;

  const isDataOutdated = useMemo(
    () =>
      !BYPASS_OUTDATED_DATA &&
      !firstLoadingSourceInfo &&
      !refetchingSourceInfo &&
      (magnetElapsedMinutes === null || magnetElapsedMinutes >= 15),
    [firstLoadingSourceInfo, magnetElapsedMinutes, refetchingSourceInfo]
  );

  const [searchValue, setSearchValue, , searchResults] =
    useSearch<TruckSummary>(trucks, filterOptions);

  const dashboardTrucksData: DashboardTrucksData = useMemo(
    () =>
      generateFilteredTrucksData(
        filterDashboardTrucks(!!searchValue, searchResults)
      ),
    [searchResults, searchValue]
  );

  const onOpenSpeedDial = useCallback((e: unknown, reason: string) => {
    if (reason === "mouseEnter" || reason === "toggle") setOpenSpeedDial(true);
  }, []);

  const onCloseSpeedDial = useCallback(() => setOpenSpeedDial(false), []);

  const onClickNavigate = useCallback(
    (truck: TruckSummary) => {
      navigate(AppRoute.PRE_ASSEMBLE_TRUCK_DETAILS.replace(":equipId", truck.id));
    },
    [navigate]
  );

  const handleDownloadExcelClick = useCallback(
    () => generateTrucksExcelPreAssignment(trucks),
    [trucks]
  );

  // Do not display loader when search is active
  const showLoadingHeader = useMemo(
    () => refetchingTrucks || pollingTrucks,
    [pollingTrucks, refetchingTrucks]
  );

  const dashboardActions = useMemo<DialAction[]>(
    () => [
 
            {
              name: "Descargar",
              onClick: handleDownloadExcelClick,
              icon: <ExcelIcon />,
            },
          
        
    ],
    [handleDownloadExcelClick, isAllowedTo, isDataOutdated]
  );
  const [time, setTime] = useState(new Date());

  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    return `${day} de ${month} del ${year}`;
  }
  useEffect(() => {
    const tick = () => {
      setTime(new Date());
    };
    const intervalId = setInterval(tick, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <ScreenContainer>
        <Box className={classes.kpis}>
          <Grid item className={classes.smallContainer}>
            <Box className={classes.shiftBox}>
              <SmallBox title={"Turno"} value={shift} icon={"SHIFT"} small={true} />
            </Box>
            <Box className={classes.dateBox}>
              <SmallBox
                title={"Fecha"}
                value={dateFormat}
                icon={"DATE"}
                small={false}
              />
            </Box>
          </Grid>
          <PreAssembleOperatorAssignmentKpis />
        </Box>
        <Box className={classes.trucksGroupsRoot}>
          {loadingTrucks ? (
            <>
              {Array.from(Array(4).keys()).map((i) => (
                <TruckGroupSkeleton key={i} />
              ))}
            </>
          ) : (
            dashboardTrucksData.map(({ columns, group }) => (
              <TruckGroup key={group}>
                {columns.map(({ trucks, column }) => (
                  <TruckColumn
                    key={column}
                    title={columnTypeTranslation(column, withSpace)}
                    chipText={`${trucks.length}`}
                  >
                    {trucks.map((truck) => (
                      clicked === 1 ?
                      <PreAssembledTruckCard
                        key={truck.id}
                        truck={truck}
                        type={column}
                        groupType={group}
                        onClick={onClickNavigate}
                        refetch={refetchTrucksDashboard}
                      /> : 
                      <PreAssembledTruckCard
                        key={truck.id}
                        truck={truck}
                        type={column}
                        groupType={group}
                        onClick={() => console.log('No se puede clickar')}
                        refetch={refetchTrucksDashboard}
                      />
                    ))}
                  </TruckColumn>
                ))}
              </TruckGroup>
            ))
          )}
        </Box>
        {isDataOutdated && (
          <CustomSnackbar
            open={true}
            onClose={() => {}}
            data={{
              severity: "error",
              text: "Problemas de actualización de datos",
            }}
          />
        )}
        <OperatorAssignmentSpeedDial
          ariaLabel="trucks-dashboard-speed-dial"
          actions={dashboardActions}
          speedDialBaseIcon={<TruckIcon />}
          openIcon={<CloseSharp />}
          onClose={onCloseSpeedDial}
          onOpen={onOpenSpeedDial}
          open={openSpeedDial}
        />
      </ScreenContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  const { type, background } = palette;
  const cellBackground =
    type === "light" ? palette.secondary.main : palette.common.white;

  return {
    trucksGroupsRoot: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: type === "light" ? background.paper : background.default,
      flexGrow: 1,
      paddingBottom: 34,
      marginTop: 34,
    },
    speedDial: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    speedDialIcon: {
      color: palette.common.white,
    },
    actions: {
      backgroundColor: palette.primary.dark,
      color: palette.common.white,
      "&:hover": {
        backgroundColor: palette.primary.dark,
      },
    },
    actionsTooltips: {
      fontSize: theme.typography.body1.fontSize,
      backgroundColor: cellBackground,
      color: palette.getContrastText(cellBackground),
    },
    kpis: {
      display: "flex",
      flex: 1,
      flexDirection: 'row',
      justifyContent: "flex-start",
      gap: '37px',
    },
    smallContainer: {
      flexDirection: "row",
      justifyContent: "flex-start",
      flex: 1,
      maxWidth: 500,
      height: 100,
      [theme.breakpoints.down('lg')]: {
        minWidth: 250,
      },
      // width: 1132,
      display: "inline-flex",
      gap: "30px",
      [theme.breakpoints.down('md')]: {
        gap: "12px"
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
        width: '100%',
        height: 200,
        gap: '16px'
      }
    },
    shiftBox: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      borderRadius: "4px",
      width: 150,
      height: 100,
      boxShadow: "none",
      padding: 0
    },
    dateBox: {
      display: "flex",
      justifyContent: "space-between",
      width: 250,
      height: 100,
      borderRadius: "6px",
      boxShadow: "none",
      padding: 0
    },
  };
});
